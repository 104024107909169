// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import breakpoints from "assets/theme/base/breakpoints";
// Vision UI Dashboard PRO React base styles
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import MiniStatisticsCard from "examples/Cards/StatisticsCards/MiniStatisticsCard";
import Footer from "examples/Footer";
import Globe from "examples/Globe";
// Vision UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import SalesTable from "examples/Tables/SalesTable";
// Data
import SalesOverview from "./components/SalesOverview";

// icons
import { FaChartLine, FaPercentage } from "react-icons/fa";

import useAppContext from "context/appContext";
import useCurrencyFormat from "../../../utils/currencyFormat";
import Trades from "../crm/trades";

import { AiFillDollarCircle } from "react-icons/ai";
import { IoIosWallet } from "react-icons/io";
import CreditBalance from "layouts/pages/account/billing/components/CreditBalance";

function Default() {
  const { values } = breakpoints;

  const { user } = useAppContext();

  const currencyFormat = useCurrencyFormat();
  const overall_return = user.total_market_value - user.purchased_amount;

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <VuiBox py={3}>
        <Grid container>
          <Grid item xs={12} lg={7}>
            <VuiBox mb={3} p={1}>
              <VuiTypography
                variant={window.innerWidth < values.sm ? "h3" : "h2"}
                textTransform="capitalize"
                fontWeight="bold"
                color="white"
              >
                Hello, {user.name} 👋
              </VuiTypography>
            </VuiBox>
            <Grid container>
              <Grid item xs={12}>
                <Globe
                  display={{ xs: "none", md: "block" }}
                  position="absolute"
                  top="10%"
                  right={0}
                  mt={{ xs: -12, lg: -10 }}
                  mr={{ xs: 0, md: -10, lg: 10 }}
                  canvasStyle={{ marginTop: "3rem" }}
                />
              </Grid>
            </Grid>

            <Grid style={{ width: "800px" }} mb={5} item xs={12} md={3} lg={12} xl={5}>
              {user.trades[0] && <CreditBalance />}
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} md={3} lg={3} xl={5}>
                <VuiBox mb={3}>
                  <MiniStatisticsCard
                    title={{ text: "portfolio value", fontWeight: "bold" }}
                    count={currencyFormat.format(user.value)}
                    percentage={{
                      color: overall_return > 0 ? "success" : "error",
                      text: `  ${overall_return > 0 ? "+" : ""}${(
                        (overall_return / user.purchased_amount) *
                        100
                      ).toFixed(2)}%`,
                    }}
                    icon={<FaChartLine color="white" />}
                  />
                </VuiBox>
                <MiniStatisticsCard
                  title={{ text: "bonus cash", fontWeight: "bold" }}
                  count={currencyFormat.format(user.bonus_cash)}
                  icon={<IoIosWallet color="white" />}
                />
              </Grid>
              <Grid item xs={12} md={5} lg={6} xl={5}>
                <VuiBox mb={3}>
                  <MiniStatisticsCard
                    title={{ text: "overall return", fontWeight: "bold" }}
                    count={`${overall_return > 0 ? "+" : ""}${currencyFormat.format(
                      overall_return
                    )}`}
                    percentage={{
                      color: overall_return > 0 ? "success" : "error",
                      text: `  ${overall_return > 0 ? "+" : ""}${(
                        (overall_return / user.purchased_amount) *
                        100
                      ).toFixed(2)}%`,
                    }}
                    icon={<FaPercentage color="white" />}
                  />
                </VuiBox>
                <VuiBox mb={3}>
                  <MiniStatisticsCard
                    title={{ text: "amount invested", fontWeight: "bold" }}
                    count={currencyFormat.format(user.purchased_amount)}
                    icon={<AiFillDollarCircle color="white" />}
                  />
                </VuiBox>
              </Grid>
            </Grid>
          </Grid>

          <Grid container spacing={3} mt="2px">
            <Grid item xs={12} lg={6} xl={7}>
              <SalesOverview />
            </Grid>
          </Grid>
          <Grid mt={5} container>
            <Grid item xs={12}>
              <Card>
                <SalesTable
                  title="Your Portfolio"
                  rows={[
                    ...new Set(user.tradesValue.sort((a, b) => b.total_amount - a.total_amount)),
                  ]
                    .filter((x) => x.value > 0)
                    .sort((a, b) => b.timestamp._seconds - a.timestamp._seconds)
                    .map((trade) => {
                      const gain =
                        Math.round(
                          (((trade["8. Bid Price"] || trade["02. open"]) *
                            parseFloat(trade.quantity)) /
                            (trade.single_amount * trade.quantity)) *
                            100 *
                            100
                        ) / 100;

                      const percentReturn = Math.round((gain - 100) * 100) / 100;

                      return {
                        symbol: trade.symbol,
                        name: trade.name || trade["2. From_Currency Name"],
                        ["Purchased Price"]: currencyFormat.format(trade.single_amount),
                        ["Current Price"]: currencyFormat.format(
                          trade["8. Bid Price"] || trade["02. open"]
                        ),
                        shares: trade.quantity,
                        ["Total Cost"]: currencyFormat.format(trade.single_amount * trade.quantity),
                        ["Market Value"]: currencyFormat.format(
                          (trade["8. Bid Price"] || trade["02. open"]) * parseFloat(trade.quantity)
                        ),
                        ["gain"]: currencyFormat.format(
                          (trade["8. Bid Price"] || trade["02. open"]) *
                            parseFloat(trade.quantity) -
                            trade.single_amount * trade.quantity
                        ),
                        Return: `${percentReturn > 0 ? "+" : ""}${percentReturn}%`,
                      };
                    })}
                />
              </Card>
            </Grid>
          </Grid>
          <Grid mt={5} container>
            <Grid item xs={12}>
              <Card>
                <Trades />
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </VuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Default;
