import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import moment from "moment";

function ProfileInfoCard({ title, summary, banner_image, time_published }) {
  // Render the card social media icons

  return (
    <Card
      sx={() => ({
        height: "100%",
      })}
    >
      <img
        loader={() => banner_image}
        src={banner_image}
        alt={title}
        width="350px"
        height="200px"
        style={{ marginBottom: "10px" }}
      />
      <VuiTypography variant="caption" color="white">
        {moment(time_published).format("MMMM Do YYYY, h:mm:ss a")}
      </VuiTypography>
      <VuiBox display="flex" mb="14px" mt="10px" justifyContent="space-between" alignItems="center">
        <VuiTypography variant="lg" fontWeight="bold" color="white" textTransform="capitalize">
          {title}
        </VuiTypography>
      </VuiBox>
      <VuiBox>
        <VuiBox mb={2} lineHeight={1}>
          <VuiTypography variant="button" color="text" fontWeight="regular">
            {summary}
          </VuiTypography>
        </VuiBox>
        <VuiBox opacity={0.3}>
          <Divider />
        </VuiBox>
      </VuiBox>
    </Card>
  );
}

export default ProfileInfoCard;
