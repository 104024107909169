export const milestones = [
  {
    id: "2k",
    title: "Bonus Cash 2K",
    subtitle: "On Return 2%",
    img: "https://firebasestorage.googleapis.com/v0/b/sba-jpis-e5e0b.appspot.com/o/achievements%2Fbadges%2Ftimeliness_common2.png?alt=media&token=c597ba91-9393-4765-9cb8-600b77496ad9",
    return: 2,
    name: "Rookie Riser",
    badgeText: "Confident start, trading finesse",
  },
  {
    id: "4k",
    title: "Bonus Cash 4K",
    subtitle: "On Return 5%",
    img: "https://firebasestorage.googleapis.com/v0/b/sba-jpis-e5e0b.appspot.com/o/achievements%2Fbadges%2Fpunctuality_common2.png?alt=media&token=eb52fb62-5700-4cb2-aad0-4e8b589f42c9",
    return: 5,
    name: "Smart Strategist",
    badgeText: "Calculated moves, exceptional gains",
  },
  {
    id: "8k",
    title: "Bonus Cash 8K",
    subtitle: "On Return 7%",
    img: "https://firebasestorage.googleapis.com/v0/b/sba-jpis-e5e0b.appspot.com/o/achievements%2Fbadges%2Fimage%203.png?alt=media&token=c1fe98ba-ad18-4e9e-99af-b6fd00bf0150",
    return: 7,
    name: "Sharp Shooter",
    badgeText: "Precision targeting, determined success",
  },
  {
    id: "12k",
    title: "Bonus Cash 12k",
    subtitle: "On Return 10%",
    img: "https://firebasestorage.googleapis.com/v0/b/sba-jpis-e5e0b.appspot.com/o/achievements%2Fbadges%2Ftimeliness_uncommon2.png?alt=media&token=f3239d8f-240c-4e27-9de4-7d1eb4bcc9c2",
    return: 10,
    name: "Market Maestro",
    badgeText: "Exceptional skills, market conqueror",
  },
  {
    id: "20k",
    title: "Bonus Cash 20k",
    subtitle: "On Return 15%",
    img: "https://firebasestorage.googleapis.com/v0/b/sba-jpis-e5e0b.appspot.com/o/achievements%2Fbadges%2Fmission_zero_badge.png?alt=media&token=ed3a37a3-de50-444c-b2d2-2230c20219f5",
    return: 15,
    name: "Astute Analyst",
    badgeText: "Insightful decisions, trading power",
  },
  {
    id: "50k",
    title: "Bonus Cash 50k",
    subtitle: "On Return 20%",
    img: "https://firebasestorage.googleapis.com/v0/b/sba-jpis-e5e0b.appspot.com/o/achievements%2Fbadges%2Fhardwork_legendary.png?alt=media&token=2a39ef9c-76ff-49d1-befa-06ceb059821d",
    return: 20,
    name: "Trade Titan",
    badgeText: "Legendary force, market dominator",
  },
];
