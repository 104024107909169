import Table from "examples/Tables/Table";
import useAppContext from "context/appContext";
import moment from "moment";
import useCurrencyFormat from "utils/currencyFormat";

const Trades = () => {
  const { user } = useAppContext();
  const currencyFormat = useCurrencyFormat();

  return (
    <div>
      <Table
        title="Past Trades"
        columns={[
          { name: "type", align: "left" },
          { name: "date", align: "left" },
          { name: "asset", align: "left" },
          { name: "quantity", align: "left" },
          { name: "amount", align: "left" },
          { name: "status", align: "left" },
        ]}
        rows={user.trades
          .sort((a, b) => b.timestamp._seconds - a.timestamp._seconds)
          .map((trade) => ({
            type: trade.type,
            date: moment.unix(trade.timestamp._seconds).format("Do MMM, YYYY hh:mm a"),
            asset: trade.symbol,
            quantity: trade.quantity,
            amount: currencyFormat.format(trade.total_amount),
            status: "Completed",
          }))}
        notes={user.trades
          .sort((a, b) => b.timestamp._seconds - a.timestamp._seconds)
          .map((trade) => ({
            note: trade.notes,
          }))}
      />
    </div>
  );
};

export default Trades;
