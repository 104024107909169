import { Card } from "@mui/material";
// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import BasicLineChart from "examples/Charts/LineCharts/BasicLineChart";
import { lineChartOptionsDashboard } from "../../data/lineChart";
import useAppContext from "context/appContext";

const SalesOverview = () => {
  const { user } = useAppContext();

  return (
    <Card>
      <VuiBox sx={{ height: "100%" }}>
        <VuiTypography variant="lg" color="white" fontWeight="bold" mb="5px">
          Daily Expenses
        </VuiTypography>

        <VuiBox sx={{ height: "250px" }}>
          <BasicLineChart
            lineChartData={[
              {
                name: "Expenses",
                data: user.expenseData.map((x) => x.Spending),
              },
            ]}
            lineChartOptions={{
              ...lineChartOptionsDashboard,
              xaxis: { categories: user.expenseData.map((x) => x.Date) },
            }}
          />
        </VuiBox>
      </VuiBox>
    </Card>
  );
};

export default SalesOverview;
