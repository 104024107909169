import { CircularProgress, Grid } from "@mui/material";
import { API } from "api/instance";
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useEffect, useState } from "react";

function News() {
  const [newsLoading, setNewsLoading] = useState(true);
  const [news, setNews] = useState([]);

  const getNews = async () => {
    console.log("fetching");
    setNewsLoading(true);
    try {
      const { data } = await API.get("/news");
      setNews(data.filter((x) => (x?.banner_image ? true : false)));
      setNewsLoading(false);
    } catch (error) {
      alert("An error occured while displaying news");
      console.log(error);
    }
  };

  useEffect(() => {
    getNews();
  }, []);
  return (
    <DashboardLayout>
      <DashboardNavbar />

      <Grid container spacing={3}>
        {newsLoading ? (
          <div
            style={{
              height: "calc(100vh - 200px)",
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress sx={{ marginBottom: "20px" }} />
          </div>
        ) : (
          <>
            {news.map((x, i) => (
              <Grid item xs={12} md={6} lg={4} key={i}>
                <ProfileInfoCard
                  banner_image={x.banner_image}
                  title={x.title}
                  summary={x.summary}
                  time_published={x.time_published}
                />
              </Grid>
            ))}
          </>
        )}
      </Grid>
    </DashboardLayout>
  );
}

export default News;
