const useCurrencyFormat = (minimumFractionDigits = 2) => {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits,
  });

  return formatter;
};

export default useCurrencyFormat;
