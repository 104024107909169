// @mui material components
// Vision UI Dashboard PRO React components
import VuiBox from "components/VuiBox";
import Footer from "examples/Footer";
// Vision UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
// Data

import { FormControl } from "@mui/material";
import VuiSelect from "components/VuiSelect";
import VuiInput from "components/VuiInput";
import VuiButton from "components/VuiButton";
import { useEffect, useRef, useState } from "react";
import { API } from "api/instance";
import StockBuy from "./stocks/buy";
import StockSell from "./stocks/sell";
import CryptoBuy from "./crypto/buy";
import CryptoSell from "./crypto/sell";
import Trades from "./trades";

function CRM() {
  const [loading, setLoading] = useState(false);

  const [results, setResults] = useState([]);

  const [type, setType] = useState("stocks");
  const [action, setAction] = useState("buy");
  const [searchQ, setSearchQ] = useState("");
  const [show, setShow] = useState(false);

  const getCrypto = async () => {
    if (!type || !action || !searchQ) {
      return alert("Please fill all fields");
    }

    setLoading(true);
    try {
      const { data } = await API.get(`/trades/crypto`, {
        params: { symbol: searchQ },
      });
      setResults(data);
      setShow(true);
      return setLoading(false);
    } catch (error) {
      console.log(error);
      alert("An error occured, try again. Make sure you are entering the correct crypto symbol");
      setLoading(false);
    }
  };

  const searchStocks = async () => {
    if (!type || !action || !searchQ) {
      return alert("Please fill all fields");
    }
    setLoading(true);
    try {
      const { data } = await API.get(`/trade/search`, {
        params: { query: searchQ },
      });
      setResults(data.bestMatches);
      return setLoading(false);
    } catch (error) {
      alert("An error occured, try again");
      setLoading(false);
    }
  };

  const formProps = {
    searchQ,
    setSearchQ,
    searchStocks,
    type,
    setType,
    action,
    setAction,
    getCrypto,
    loading,
    setResults,
    setShow,
  };

  const ref = useRef();
  const [availableWidth, setAvailableWidth] = useState(0);

  useEffect(() => {
    setAvailableWidth(ref.current ? ref.current.offsetWidth - 50 : 800);
  }, [ref.current]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <VuiBox py={3} ref={ref}>
        <VuiBox mb={3}>
          <p className="bg-white">Trading has closed</p>
          {/* <InitalForm {...formProps} />

          {type === "stocks" && action === "buy" && !loading && (
            <StockBuy results={results} availableWidth={availableWidth} />
          )}

          {type === "stocks" && action === "sell" && !loading && <StockSell />}

          {type === "crypto" && action === "buy" && show && !loading && (
            <CryptoBuy result={results} availableWidth={availableWidth} />
          )}
          {type === "crypto" && action === "sell" && !loading && <CryptoSell />}
          <div style={{ marginTop: "3rem" }}>
            <Trades />
          </div> */}
        </VuiBox>
      </VuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default CRM;

export const InitalForm = (props) => {
  const {
    searchQ,
    setSearchQ,
    searchStocks,
    type,
    setType,
    action,
    setAction,
    getCrypto,
    loading,
    setResults,
    setShow,
  } = props;
  const label = type !== "crypto" ? "Search Stock by Name or Symbol" : "Enter the Crypto Symbol";

  const handleClick = (e) => {
    e.preventDefault();
    if (type === "stocks" && action === "buy") return searchStocks();
    if (type === "crypto" && action === "buy") return getCrypto();
  };

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "1.25rem" }}>
        <div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ marginRight: "1.25rem" }}>
              <FormControl sx={{ width: 200 }}>
                <VuiSelect
                  value={{ value: type, label: type === "stocks" ? "Stock" : "Cryptocurrency" }}
                  onChange={(e) => {
                    setResults([]);
                    setType(e.value);
                  }}
                  options={[
                    { value: "stocks", label: "Stock" },
                    { value: "crypto", label: "Cryptocurrency" },
                  ]}
                />
              </FormControl>
            </div>
            <VuiButton
              disabled={loading}
              style={{ marginRight: "15px" }}
              onClick={() => {
                setResults([]);
                setAction("buy");
              }}
              variant={action === "buy" ? "contained" : "outlined"}
            >
              Buy
            </VuiButton>
            <VuiButton
              onClick={() => {
                setResults([]);
                setShow(false);
                setAction("sell");
              }}
              variant={action === "sell" ? "contained" : "outlined"}
              disabled={loading}
            >
              Sell
            </VuiButton>
          </div>
        </div>
      </div>
      <form onSubmit={handleClick} style={{ display: "flex", alignItems: "center" }}>
        <div style={{ width: "100%" }}>
          <VuiInput
            placeholder={label}
            fullWidth
            value={searchQ}
            onChange={({ target }) => setSearchQ(target.value)}
            style={{ height: "55px !important" }}
          />
        </div>
        <div style={{ marginLeft: "1.25rem" }}>
          <VuiButton
            disabled={loading || !searchQ || !type || !action}
            variant="gradient"
            color="info"
            type="submit"
            style={{ width: "210px", fontSize: "14px" }}
          >
            {loading ? "Loading..." : "Find"}
          </VuiButton>
        </div>
      </form>
    </div>
  );
};
