import Lottie from "lottie-react";
import { useEffect, useRef } from "react";
import animation from "assets/animation/loader.json";
import VuiTypography from "./VuiTypography";

const Loader = () => {
  const lottieRef = useRef();

  useEffect(() => {
    lottieRef.current.setSpeed(0.2);
  }, [lottieRef]);

  return (
    <div
      id="loader"
      style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Lottie
        lottieRef={lottieRef}
        animationData={animation}
        loop
        autoPlay
        style={{ width: "50vw" }}
        speed={0.2}
      />

      <VuiTypography color="white" variant="light" mt="15px">
        Loading Dashboard...
      </VuiTypography>
    </div>
  );
};

export default Loader;

{
  /* <svg
        className={styles.mainSvg}
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 2000 750"
      >
        <defs>
          <linearGradient id="left-to-right">
            <stop offset="0" stop-color="#eb0028">
              <animate
                dur=".75s"
                attributeName="offset"
                begin=".25s"
                fill="freeze"
                from="0"
                to="1"
              ></animate>
            </stop>
            <stop offset="0" stop-color="#f8f8f8">
              <animate
                dur=".75s"
                attributeName="offset"
                begin=".25s"
                fill="freeze"
                from="0"
                to="1"
              ></animate>
            </stop>
          </linearGradient>
          <linearGradient id="left-to-right2">
            <stop offset="0" stop-color="#eb0028">
              <animate
                dur=".73s"
                attributeName="offset"
                begin="1.04s"
                fill="freeze"
                from="0"
                to="1"
              ></animate>
            </stop>
            <stop offset="0" stop-color="#f8f8f8">
              <animate
                dur=".73s"
                attributeName="offset"
                begin="1.04s"
                fill="freeze"
                from="0"
                to="1"
              ></animate>
            </stop>
          </linearGradient>
          <linearGradient id="left-to-right3">
            <stop offset="0" stop-color="#eb0028">
              <animate
                dur=".824s"
                attributeName="offset"
                begin="1.81s"
                fill="freeze"
                from="0"
                to="1"
              ></animate>
            </stop>
            <stop offset="0" stop-color="#f8f8f8">
              <animate
                dur=".824s"
                attributeName="offset"
                begin="1.81s"
                fill="freeze"
                from="0"
                to="1"
              ></animate>
            </stop>
          </linearGradient>
          <linearGradient id="left-to-right4">
            <stop offset="0" stop-color="#eb0028">
              <animate
                dur=".57s"
                attributeName="offset"
                begin="2.664s"
                fill="freeze"
                from="0"
                to="1"
              ></animate>
            </stop>
            <stop offset="0" stop-color="#f8f8f8">
              <animate
                dur=".57s"
                attributeName="offset"
                begin="2.664s"
                fill="freeze"
                from="0"
                to="1"
              ></animate>
            </stop>
          </linearGradient>
        </defs>
        <path
          className={styles.svg1}
          d="M205.62,240.63H50.79V98.84H530.5V240.63H375.73V652.07H205.62Z"
          fill="url(#left-to-right)"
          stroke="#eb0028"
          stroke-width="18px"
        ></path>
        <path
          className={styles.svg1}
          d="M556.94,98.84h465.67V240.63H727.16v68.94h295.45V441.34H727.16v68.94h295.53V652.07H556.94Z"
          fill="url(#left-to-right2)"
        ></path>
        <path
          className={styles.svg1}
          d="M1051.26,98.84h279.33c184.12,0,249.2,136.37,249.2,275.8,0,169.74-89.76,277.43-282.44,277.43H1051.26Zm170.21,411.44H1288c106,0,121.54-86,121.54-138,0-34.8-10.89-131.65-133.93-131.65h-54.17Z"
          fill="url(#left-to-right3)"
        ></path>
        <path
          className={styles.svg1}
          d="M1824.37,440.24,1772.44,354l-50.63,86.22H1597.1L1715.49,265.9l-114-166.76h124.75l46.2,82.43L1820,99.14h124.72l-114,166.76,118.4,174.34Z"
          fill="url(#left-to-right4)"
        ></path>
      </svg> */
}
