import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyB92i-4nrMIRQxtUeocyUrT1RZCv51x7K8",
  authDomain: "stocks-simulator-jpis.firebaseapp.com",
  projectId: "stocks-simulator-jpis",
  storageBucket: "stocks-simulator-jpis.appspot.com",
  messagingSenderId: "441723548638",
  appId: "1:441723548638:web:0ac78fd07cb5045b6f634f",
  measurementId: "G-Z6G6FHFBS1",
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);

if (app.name && typeof window !== "undefined") {
  getAnalytics(app);
}
