import { API } from "api/instance";
import { auth } from "lib/firebase";
import { createContext, useContext, useEffect, useState } from "react";
import { getIdToken, onAuthStateChanged } from "firebase/auth";
import { milestones } from "../json/milestones";

const AppContext = createContext();

const useAppContext = () => useContext(AppContext);

export const AppContextProvider = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);

  const getUserData = (userParam = user) => {
    setLoading(true);

    API.get("/user", { params: { email: userParam.email } })
      .then((data) => {
        setUser({
          ...data.data,
          extra: userParam,
          // percentageReturn: 5,
        });
        setLoading(false);
      })
      .catch(() => {
        alert("An error occured, refresh try again");
      });
  };
  const [newAchievements, setNewAchievements] = useState([]);

  useEffect(() => {
    if (user && !loading) {
      const checkNewMilestones = () => {
        const updatedAchievements = [];
        milestones.forEach((milestone) => {
          if (
            user.percentageReturn >= milestone.return &&
            !user.achievements.includes(milestone.id)
          ) {
            updatedAchievements.push(milestone.id);
          }
        });
        setNewAchievements(updatedAchievements);
      };

      checkNewMilestones();
    }
  }, [user, loading]);

  useEffect(() => {
    if (newAchievements.length > 0 && user && !loading) {
      console.log("updating");
      API.post("/user/achievements", {
        email: user.email,
        newAchievements,
      }).then(() => {
        setUser({
          ...user,
          achievements: [...user.achievements, ...newAchievements],
        });
      });
    }
  }, [newAchievements, loading]);

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        getIdToken(user, true).then((idToken) => {
          API.defaults.headers.common["Authorization"] = `Bearer ${idToken}`;
          API.defaults.headers.common["Content-Type"] = "application/json";
          API.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
          API.defaults.headers.common["Origin"] = "http://localhost:3000";
          //   Origin: "http://localhost:3000",

          getUserData(user, idToken);
        });
      } else {
        setUser(null);
        setLoading(false);
      }
    });
  }, []);
  return (
    <AppContext.Provider value={{ user, loading, getUserData }}>{children}</AppContext.Provider>
  );
};

export default useAppContext;
