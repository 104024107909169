import React from "react";
import { Card, Stack } from "@mui/material";
import balance from "assets/images/billing-background-balance.png";
import Graph from "assets/images/shapes/graph-billing.svg";
import palette from "assets/theme/base/colors";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import useAppContext from "context/appContext";
import useCurrencyFormat from "utils/currencyFormat";
import moment from "moment";
import { BsArrowDownCircleFill, BsArrowUpCircleFill } from "react-icons/bs";

const CreditBalance = () => {
  const { user } = useAppContext();

  const currencyFormat = useCurrencyFormat();

  const latestTrade = user.trades.sort((a, b) => b.timestamp._seconds - a.timestamp._seconds)[0];

  return (
    <Card sx={{ padding: "30px", width: "650px", maxWidth: "700px" }}>
      <VuiBox display="flex" flexDirection="column">
        <VuiBox
          mb="32px"
          p="20px"
          display="flex"
          flexDirection="column"
          sx={{ backgroundImage: `url(${balance})`, backgroundSize: "cover", borderRadius: "18px" }}
        >
          <VuiBox display="flex" justifyContent="space-beetween" alignItems="center">
            <VuiTypography variant="caption" color="white" fontWeight="medium" mr="auto">
              Cash Balance
            </VuiTypography>
          </VuiBox>
          <VuiBox display="flex" justifyContent="space-beetween" alignItems="center">
            <VuiTypography variant="h2" color="white" fontWeight="bold" mr="auto">
              {currencyFormat.format(user.currBalance)}
            </VuiTypography>
            <VuiBox component="img" src={Graph} sx={{ width: "58px", height: "20px" }} />
          </VuiBox>
        </VuiBox>
        <VuiTypography color="text" variant="xxs" fontWeight="medium" mb="8px">
          LATEST
        </VuiTypography>
        <VuiBox display="flex" justifyContent="space-beetween" alignItems="center">
          <Stack direction="row" spacing="10px" mr="auto">
            <VuiBox
              display="flex"
              mr="10px"
              justifyContent="center"
              alignItems="center"
              sx={{
                background: "rgba(34, 41, 78, 0.7)",
                borderRadius: "50%",
                width: "42px",
                height: "42px",
              }}
            >
              {latestTrade.type === "buy" ? (
                <BsArrowDownCircleFill color={palette.error.main} size="18px" />
              ) : (
                <BsArrowUpCircleFill color={palette.success.main} size="18px" />
              )}
            </VuiBox>
            <VuiBox display="flex" flexDirection="column">
              <VuiTypography color="white" variant="button" fontWeight="medium">
                {latestTrade?.symbol || "N/A"}
              </VuiTypography>
              <VuiTypography color="text" variant="button" fontWeight="medium">
                {latestTrade.type === "buy" ? "Purchased" : "Sold"}{" "}
                {moment.unix(latestTrade?.timestamp?._seconds)?.calendar() || "N/A"}
              </VuiTypography>
            </VuiBox>
          </Stack>
          <VuiTypography variant="button" color="white" fontWeight="bold">
            {latestTrade?.type === "buy" ? "-" : "+"}{" "}
            {currencyFormat.format(latestTrade?.total_amount) || "N/A"}
          </VuiTypography>
        </VuiBox>
      </VuiBox>
    </Card>
  );
};

export default CreditBalance;
