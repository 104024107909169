// Vision UI Dashboard PRO React layouts
import Default from "layouts/dashboards/default";
import CRM from "layouts/dashboards/crm";
import SignInCover from "layouts/authentication/sign-in/cover";
import AllProjects from "layouts/pages/profile/all-projects";

import { IoHome } from "react-icons/io5";
import News from "layouts/dashboards/news";

const routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <IoHome size="15px" color="inherit" />,
    collapse: [
      {
        name: "Home",
        key: "home",
        route: "/dashboard/home",
        component: Default,
      },
      { name: "Trade", key: "trade", route: "/dashboard/trade", component: CRM },
      {
        name: "Achievements",
        key: "achievements",
        route: "/dashboard/achievements",
        component: AllProjects,
      },
      { name: "News", key: "news", route: "/dashboard/news", component: News },
      {
        route: "/login",
        component: SignInCover, // Import your login component
        key: "login",
        name: "Login",
      },
    ],
  },
];

export default routes;
