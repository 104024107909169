import PropTypes from "prop-types";

import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";

import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";

function ComplexProjectCard({ unlocked, title, description, badgeImage, badgeText }) {
  return (
    <Card sx={{ minHeight: "100%" }}>
      <VuiBox>
        <VuiBox display="flex" alignItems="center">
          <img
            src={badgeImage}
            alt="badge"
            style={{
              height: "80px",
              marginLeft: "10px",
              filter: unlocked ? "grayscale(0%)" : "grayscale(100%)",
            }}
          />
          <VuiBox ml={2} lineHeight={0}>
            <VuiBox mb={1} lineHeight={0}>
              <VuiTypography
                variant="h6"
                color="white"
                textTransform="capitalize"
                fontWeight="medium"
              >
                {title}
              </VuiTypography>
            </VuiBox>

            <VuiTypography variant="caption" color="light">
              {badgeText}
            </VuiTypography>
          </VuiBox>
        </VuiBox>
        <VuiBox my={2} lineHeight={1}>
          <VuiTypography variant="button" fontWeight="regular" color="text">
            {description}
          </VuiTypography>
        </VuiBox>
        <Divider light />
        <VuiBox display="flex" justifyContent="space-between" alignItems="center">
          <VuiBox display="flex" flexDirection="column" lineHeight={0}>
            <VuiTypography variant="button" color="white" fontWeight="medium">
              {unlocked ? "Milestone Reached" : "Milestone Pending"}
            </VuiTypography>
            <VuiTypography variant="button" fontWeight="medium" color="text">
              Current Staus
            </VuiTypography>
          </VuiBox>
        </VuiBox>
      </VuiBox>
    </Card>
  );
}

// Setting default values for the props of ComplexProjectCard
ComplexProjectCard.defaultProps = {
  color: "dark",
  dateTime: "",
  members: [],
  dropdown: false,
};

// Typechecking props for the ProfileInfoCard
ComplexProjectCard.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
    "light",
  ]),
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  dateTime: PropTypes.string,
  description: PropTypes.node.isRequired,
  members: PropTypes.arrayOf(PropTypes.string),
  dropdown: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      action: PropTypes.func,
      menu: PropTypes.node,
    }),
  ]),
};

export default ComplexProjectCard;
