import Grid from "@mui/material/Grid";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";
import ComplexProjectCard from "examples/Cards/ProjectCards/ComplexProjectCard";
import Header from "layouts/pages/profile/profile-overview/components/Header/index";
import { milestones } from "json/milestones";
import useAppContext from "context/appContext";

function AllProjects() {
  const { user } = useAppContext();

  return (
    <DashboardLayout>
      <Header />
      <VuiBox mt="30px" mb="24px">
        <Grid container>
          <Grid item xs={12}>
            <VuiBox mb={1}>
              <VuiTypography variant="lg" color="white" fontWeight="bold">
                Trade, Triumph & Earn Rewards
              </VuiTypography>
            </VuiBox>
            <VuiBox mb="40px">
              <VuiTypography fontSize={16} color="text" fontWeight="regular">
                Discover your trading potential by unlocking badges based on percent returns, and
                celebrate your accomplishments with bonus cash. Boost your portfolio and elevate
                your stock trading journey one badge at a time!
              </VuiTypography>
            </VuiBox>
          </Grid>
        </Grid>
        <VuiBox mb={1}>
          <Grid container spacing={3}>
            {milestones.map((milestone, i) => (
              <Grid item xs={12} md={6} lg={4} key={i}>
                <ComplexProjectCard
                  title={milestone.name}
                  color="info"
                  badgeImage={milestone.img}
                  description={`Earn a  $${milestone.id} bonus on achieving return of ${milestone.return}%`}
                  badgeText={milestone.badgeText}
                  unlocked={user?.achievements?.includes(milestone.id)}
                />
              </Grid>
            ))}
          </Grid>
        </VuiBox>
      </VuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default AllProjects;
